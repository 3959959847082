<template>
    <div class="container h-100 my-2">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                <a :href="this.client_area" class="d-block mb-3" v-if="this.client_area"><font-awesome-icon class="mr-1" :icon="['fal', 'arrow-left']"/> {{ $t('global.retour') }}</a>
				<router-link :to="{ name: 'Home' }" class="d-block mb-3" v-else><font-awesome-icon class="mr-1" :icon="['fal', 'arrow-left']"/> {{ $t('global.retour') }}</router-link>
                <div class="box">
                <h2>{{ $t('compte.reinitialiser_mdp') }}</h2>
                    <form @submit.prevent="validForm">

                        <!-- Nom -->
                        <div class="form-group">
                            <label for="name">{{ $t('compte.email') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input type="email" class="form-control" name="email" :placeholder="$t('compte.email')" v-model="email" :disabled="isWorking" autocomplete="off" required>
                            </div>
                        </div>

                        <!-- Message d'erreur si besoin -->
                        <ErrorAlert v-if="error_code !== ''" :messageI18n="'error.'+error_code" />

                        <!-- Bouton de validation du formulaire -->
                        <button type="submit" v-on:click="checkForm" class="mt-4 btn btn-primary btn-block rounded-pill">
                            {{ $t('global.reinitialiser') }}
                            <font-awesome-icon v-if="isWorking" class="mr-1" :icon="['fas', 'spinner']" spin />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
	import _debounce from 'lodash/debounce'
    import Constants from '../../config.local.js'
    import { EventBus } from 'EventBus';
    import ErrorAlert from "GroomyRoot/components/Alert/ErrorAlert";
    import User from "@/mixins/User.js";

    export default {
        name: "AskResetPassword",
        mixins: [User],
        data () {
            return {
                email: null,
                message_erreur_code: "",
                isWorking: false,
				error_code: '',
				
				client_area: null
            }
		},
		created() {
			if(this.$route.query.ca) {
				this.client_area = this.$route.query.ca
			}

			this.debounceRedirect = _debounce(() => {
				window.location = this.client_area
			}, 1000)
		},
        mounted() {
            this.init_component()
        },
		methods: {
            async init_component() {
                const actual_date = new Date()
                this.datenaissance = actual_date.toDateInputValue()
            },

            async validForm() {
                const params = {
                    email: this.email,
                }
                this.isWorking = true

                this.$request.request_post_api("ResetPassword::validForm", Constants.RESET_PWD_URL_API, params, false)
                .then((response_register) => {
                    // Si tout s'est bien passé, on redirige
                    if(response_register.code_retour == 0) {
						this.successToast()

						if(this.client_area) {
							this.debounceRedirect()
						}
						else {
							this.$router.go("-1")
						}
                    }

                    // Sinon, on affiche l'erreur
                    else {
                        console.log("An error occured:", response_register)
                    }
                    this.isWorking = false
                })
                .catch((error) => {
                    if(error.response.data) {
                        this.error_code = error.response.data.code_retour
                    }
                    this.isWorking = false
                })
            },

            successToast() {
                const params = {
                    title: this.getTrad('global.information'),
                    body: this.getTrad('compte.toast.reset_password_success'),
                    type: 'success'
                }
                EventBus.$emit('Header::displayToast', params)
			}
        },
        components: {
            ErrorAlert
        }
    };
</script>
